import {
  FooterContainer,
  FooterItem,
  Heading,
  Links,
  LogoText,
  Navigation,
  NavigationItem,
  Paragraph,
  SocialMediaList,
  useLinkWrapper,
} from '@koaly/koaly-ui';
import { withExtra } from '@koaly/with-extra';
import React, { ReactNode } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export interface IFooter {
  socialMediaLinks?: { linkedin?: string; facebook?: string; twitter?: string };
  copyrightText: ReactNode;
  menu1Title: string;
  menu1Links: Links;
  menu2Title: string;
  menu2Links: Links;
  menu3Title?: string;
  menu3Links?: Links;
}

const FooterItemTop = styled(FooterItem)`
  ${down('xs')} {
    text-align: center;

    ul {
      display: none;
    }

    p {
      display: none;
    }
  }
`;

const FooterItemBottom = styled(FooterItem)`
  ${down('xs')} {
    display: block !important;
    text-align: center;

    ul {
      justify-content: center;
    }
  }
`;


export const FooterKoaly = withExtra<IFooter>(
  ({ copyrightText, menu1Title, menu1Links, menu2Title, menu2Links, menu3Title, menu3Links, socialMediaLinks }) => {
    const linkWrap = useLinkWrapper();
    const footerSection = (
      <React.Fragment>
        <Paragraph size="small" spaceAfter={8}>
          {copyrightText}
        </Paragraph>
        {socialMediaLinks && (
          <SocialMediaList {...socialMediaLinks} />
        )}
      </React.Fragment>
    );

    return (
      <FooterContainer>
        <FooterItemTop width={{ xs: 1, s: 'stretch', m: 'stretch' }}>
          <LogoText spaceAfter={8} />
          {footerSection}
        </FooterItemTop>
        <FooterItem width={{ xs: 1, s: 'stretch', m: 2 / 12 }}>
          <Heading size="h5" as="h2" spaceAfter={6}>
            {menu1Title}
          </Heading>
          <Navigation spacing={4}>
            {menu1Links.map((link, i) =>
              linkWrap(
                <NavigationItem key={i} href={link.href} onClick={link.onClick} size="small">
                  {link.label}
                </NavigationItem>,
              ),
            )}
          </Navigation>
        </FooterItem>
        <FooterItem width={{ xs: 1, s: 'stretch', m: 2 / 12 }}>
          <Heading size="h5" as="h2" spaceAfter={6}>
            {menu2Title}
          </Heading>
          <Navigation spacing={4}>
            {menu2Links.map((link, i) =>
              linkWrap(
                <NavigationItem key={i} href={link.href} onClick={link.onClick} size="small">
                  {link.label}
                </NavigationItem>,
              ),
            )}
          </Navigation>
        </FooterItem>
        {menu3Title && menu3Links && (
          <FooterItem width={{ xs: 1, s: 'stretch', m: 2 / 12 }}>
            <Heading size="h5" as="h2" spaceAfter={6}>
              {menu3Title}
            </Heading>
            <Navigation spacing={4}>
              {menu3Links.map((link, i) =>
                linkWrap(
                  <NavigationItem key={i} href={link.href} onClick={link.onClick} size="small">
                    {link.label}
                  </NavigationItem>,
                ),
              )}
            </Navigation>
          </FooterItem>
        )}
        <FooterItemBottom width={1} visible={{ xs: true, s: false }}>
          {footerSection}
        </FooterItemBottom>
      </FooterContainer>
    );
  },
  { name: 'footer' },
);

export const Footer: React.FC = () => {
  return (
    <FooterKoaly
      socialMediaLinks={{
        facebook: 'https://www.facebook.com/Koaly-105322771193261',
        linkedin: 'https://www.linkedin.com/company/47594736',
      }}
      copyrightText={
        <React.Fragment>
          © Copyright {new Date().getFullYear()} Koaly <br /> Alle rechten voorbehouden.
        </React.Fragment>
      }
      menu1Title="Product"
      menu1Links={[
        { label: 'Functionaliteiten', href: '/functionaliteiten' },
        { label: 'Over ons', href: '/over-ons' },
      ]}
      menu2Title="Legal"
      menu2Links={[
        { label: 'Contact', href: '/contact' },
        {
          label: 'Algemene voorwaarden',
          href: 'https://drive.google.com/file/d/1EXrCCNC79AjFsoSeMrTVzDEPfMEqW84N/view?usp=sharing',
        },
        { label: 'Privacybeleid', href: '/privacy' },
      ]}
    />
  );
};
