import { TopBar as TopBarKoaly } from '@koaly/koaly-ui';
import { Spacings } from '@koaly/mixins';
import React from 'react';

export const TopBar: React.FC<{ spaceAfter?: Spacings, variant?: 'primary' | 'secondary'}> = ({ spaceAfter, variant }) => (
  <TopBarKoaly
    links={[
      { label: 'Home', href: '/' },
      { label: 'Functionaliteiten', href: '/functionaliteiten' },
      { label: 'Over ons', href: '/over-ons' },
      { label: 'Contact', href: '/contact' },
    ]}
    variant={variant}
    buttons={[{ label: 'Inschrijven', href: '#inschrijven' }]}
    spaceAfter={spaceAfter}
    logoLink={{ href: '/' }}
  />
);
