import { Button, Input, InputButtonGroup, StatusMessages, useToasts } from '@koaly/koaly-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledRegisterForm = styled.form`
  max-width: 520px;
  text-align: left;
  display: inline-block;
  width: 100%;
`;

const StyledStatusMessages = styled(StatusMessages)`
  padding-top: 10px;
  text-align: center;

  @media (min-width: 48em) {
    padding-left: 30px;
    text-align: left;
    position: absolute;
  }
`;

export const RegisterForm: React.FC = () => {
    const { register, handleSubmit, errors, reset } = useForm();
    const { addToast } = useToasts();
    const onSubmit = (data: { email: string }) => {
      addToast('Bedankt voor je inschrijving, we nemen zo snel mogelijk contact met je op.', {
        appearance: 'success',
        autoDismiss: true,
      });
      fetch('https://api.hsforms.com/submissions/v3/integration/submit/7991192/17750356-265f-421b-a7f0-9126a5c22dc8', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [
            {
              name: 'email',
              value: data.email,
            },
          ],
        }),
      });
      reset();
    };

    return (
      <StyledRegisterForm onSubmit={handleSubmit(onSubmit)}>
        <InputButtonGroup elevation={2} width={520}>
          <Input
            ref={register<HTMLInputElement>({
              required: 'Er is geen e-mailadres ingevoerd.',
              pattern: {
                // eslint-disable-next-line no-control-regex
                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: 'Het e-mailadres dat is ingevoerd is ongeldig.',
              },
            })}
            label="E-mailadres"
            hideLabel={true}
            size="large"
            name="email"
            hasError={!!errors['email']}
            elevation="none"
            type="email"
            placeholder="Je e-mailadres"
          />
          <Button>Inschrijven</Button>
        </InputButtonGroup>
        {errors.email && <StyledStatusMessages items={errors.email.message} type="danger" />}
      </StyledRegisterForm>
    );
  }
;
