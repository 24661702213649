import { TitleContent } from '@koaly/koaly-ui';
import { RegisterForm } from 'components/RegisterForm/RegisterForm';
import React from 'react';

export const FooterCTAWaitingList: React.FC = () => {
  return (
    <TitleContent
      as="section"
      spacing={true}
      titleSpaceAfter={10}
      variant="secondary"
      title="Blijf op de hoogte"
      align="center"
      id="inschrijven"
      description={
        'We kunnen niet wachten om Koaly aan je te laten zien. \n' +
        'Schrijf je in en ontvang een bericht zodra we live zijn.'
      }
    >
      <RegisterForm />
    </TitleContent>
  );
};
